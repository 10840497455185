@import "globals";

html,
body,
#root,
.fullscreen {
  height: 100%;
}

.trainer-column {
  flex: 0 1 400px;
}

.column-container {
  display: flex;
  height: 100%;
  padding-top: 1em;
}

.side-filler {
  flex: 1;
}
