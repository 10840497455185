@import "../globals";

.vcontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.top-card {
  flex: 1;
}

.bottom-card {
}

.filler-card {
  flex: 0 0 60px;
}

.horizontal-box {
  align-items: center;
  color: #fff;
  display: inline-flex;
}

.horizontal-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-bottom: 2em;
}

.center-justify {
  justify-content: center;
}

div[tabindex="-1"] {
  outline: 0px;
}
